import Swal from "sweetalert2";

function showSuccess(title, text) {
    Swal.fire({
        icon: "success",
        title: title,
        text: text,
        customClass: {
            confirmButton: "popup-button",
        },
        didOpen: () => {
            Swal.hideLoading();
        }
    });
}

function showError(title, html) {
    Swal.fire({
        icon: "error",
        title: title,
        html: html,
        customClass: {
            confirmButton: "popup-button",
        },
        didOpen: () => {
            Swal.hideLoading();
        }
    });
}

function showLoading(loadingText) {
    Swal.fire({
        icon: "info",
        title: loadingText,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
            Swal.showLoading();
        }
    })
}

function showDetails(name, description, image) {
    Swal.fire({
        title: name,
        text: description,
        imageUrl: image.src,
        imageWidth: 400,
        imageAlt: image.alt,
        customClass: {
            image: "popup-image",
            confirmButton: "popup-button",
            popup: "popup",
            title: "popup-title",
            htmlContainer: "popup-desc"
        }
    })
}

export {showSuccess, showError, showLoading, showDetails}