const BACK_URL = "https://pinklantern.be"
const API_URL = `${BACK_URL}/api`

function requestText(target, locale) {
    return new Promise((resolve, reject) => {
        fetch(`${API_URL}/${target}?locale=${locale}`)
            .then(response => response.json())
            .then(result => resolve(result.data.attributes))
            .catch(error => reject(error))
    })
}

function requestPaintings(locale) {
    return new Promise((resolve, reject) => {
        fetch(`${API_URL}/paintings?populate=*&locale=${locale}`)
            .then(response => response.json())
            .then(result => resolve(result.data.map(painting => painting.attributes)))
            .catch(error => reject(error))
    })
}

function postMail(mail) {
    return new Promise((resolve, reject) => {
        fetch(`${API_URL}/contact`, {method: "POST", body: JSON.stringify(mail)})
            .then(response => resolve(response))
            .catch(error => reject(error))
    })
}

function getImageUrl(imageUrl) {
    return BACK_URL + imageUrl
}

export {requestText, requestPaintings, postMail, getImageUrl}