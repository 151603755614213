import {postMail} from "./apiRequests.mjs"

function generateError(firstName, lastName, email, message) {
    let errors = []

    if(firstName === "") errors.push("error_firstname_empty")
    else if(!firstName.match(/^[A-Za-z\-. ]+$/)) errors.push("error_firstname_invalid")
    if(lastName === "") errors.push("error_lastname_empty")
    else if(!lastName.match(/^[A-Za-z\-. ]+$/)) errors.push("error_lastname_invalid")
    if(email === "") errors.push("error_email_empty")
    else if(!email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/)) errors.push("error_email_invalid")
    if(message === "") errors.push("error_message_empty")

    return errors;
}

function sendMail(values) {
    return new Promise((resolve, reject) => {
        let errors = generateError(values.firstName, values.lastName, values.email, values.message)

        if(errors.length > 0) reject({type: "invalid", errors: errors})
        else {
            postMail({email: values.email, firstName: values.firstName, lastName: values.lastName, message: values.message})
                .then(response => response.json())
                .then(result => {
                    if(!result.ok) {
                        if(result.errors !== undefined) reject({type: "invalid_server", errors: result.errors})
                        else reject({type: "server", error: result.error})
                    }
                    else resolve()
                })
                .catch(error => reject({type: "server", error: error}));
        }
    })
}

export default sendMail