function emptyInputs() {
    document.querySelectorAll("form input, form textarea").forEach(input => input.value = "")
}


function fillHeader(values) {
    document.querySelector("header nav ul li a[href='#work']").textContent = values.WorkLink;
    document.querySelector("header nav ul li a[href='#gallery']").textContent = values.GalleryLink;
    document.querySelector("header nav ul li a[href='#contact']").textContent = values.ContactLink;
}

function fillHomePage(values) {
    document.querySelector("#home .text").textContent = values.Text
}

function fillWorkPage(values) {
    document.querySelector("#work .text h2").textContent = values.Title;
    document.querySelector("#work .text p").textContent = values.Text;
}

function fillGalleryPage(values) {
    document.querySelector("#gallery h2").textContent = values.Title;
    document.querySelector("#gallery .galerie p").textContent = values.EmptyGalleryMessage;
}

function fillContactPage(values) {
    document.querySelector("#contact h2").textContent = values.Title;
    document.querySelector("#contact p").textContent = values.Description;
    document.querySelector("#contact form input#i_firstname").placeholder = values.FirstNamePlaceholder;
    document.querySelector("#contact form input#i_lastname").placeholder = values.LastNamePlaceholder;
    document.querySelector("#contact form input#i_email").placeholder = values.EmailPlaceholder;
    document.querySelector("#contact form textarea#i_message").placeholder = values.MessagePlaceholder;
    document.querySelector("#contact form button#b_submit span").textContent = values.SendButtonText;
}

function createImg(src, alt) {
    let img = document.createElement("img")
    img.src = src
    img.alt = alt
    return img;
}

function toggleMenu() {
    if(window.innerWidth > 1000) return;
    if(document.querySelector("header nav").classList.contains("active")) {
        document.querySelector("header nav").classList.remove("active");
        document.getElementById("b_navbar").classList.remove("active");
        document.querySelector("body").style.overflowY = "auto";
    } else {
        document.querySelector("header nav").classList.add("active");
        document.getElementById("b_navbar").classList.add("active");
        document.querySelector("body").style.overflowY = "hidden";
    }
}

function attachPopup(image, showPopup) {
    image.addEventListener("click", showPopup);
}

function getInputs() {
    return {
        firstName: document.getElementById("i_firstname"),
        lastName: document.getElementById("i_lastname"),
        email: document.getElementById("i_email"),
        message: document.getElementById("i_message")
    }
}

function emptyGallery() {
    document.querySelector("#gallery .galerie").innerHTML = ""
}

export {fillHeader, fillHomePage, fillWorkPage, fillGalleryPage, fillContactPage, createImg, toggleMenu, attachPopup, getInputs, emptyGallery, emptyInputs}