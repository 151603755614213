import {
    fillHeader,
    fillHomePage,
    fillWorkPage,
    fillGalleryPage,
    fillContactPage,
    createImg,
    toggleMenu,
    attachPopup,
    getInputs,
    emptyGallery,
    emptyInputs
} from "./domFunctions.mjs"
import sendMail from './mailSender.mjs'
import {requestPaintings, requestText, getImageUrl} from "./apiRequests.mjs";
import {showDetails, showError, showLoading, showSuccess} from "./alerts.mjs";

const LANGUAGES = [ "de", "fr"]
const LOCALE = getLocale()

function getLocale() {
    let locale = new URLSearchParams(window.location.search).get("locale")
    if(locale === undefined || locale === null || !LANGUAGES.includes(locale)) {
        locale = navigator.language.substring(0, 2)
        if(!LANGUAGES.includes(locale)) locale = navigator.languages.find(language => LANGUAGES.includes(language.substring(0, 2)))?.substring(0, 2)
        locale = locale ?? LANGUAGES[0]
    }
    return locale;
}

function main() {
    let contactFeedback

    document.documentElement.lang = LOCALE

    document.getElementById("b_submit").addEventListener("click", () => {
        showLoading(contactFeedback.loading_text)
        let inputs = getInputs()
        sendMail({firstName: inputs.firstName.value.trim(), lastName: inputs.lastName.value.trim(), email: inputs.email.value.trim(), message: inputs.message.value.trim()})
            .then(() => {
                showSuccess(contactFeedback.success_title, contactFeedback.success)
                emptyInputs()
            })
            .catch(error => {
                let errorHtml
                if(error.type === "server") errorHtml = `<p>CODE: ${error.error.code}</p>`
                else errorHtml = `<ul style='margin: 0 auto; width: fit-content; text-align: left; list-style-type: disc; list-style-position: inside;'>${error.errors.map(errorId => `<li>${contactFeedback[errorId]}</li>`).join("")}</ul>`
                showError(contactFeedback[error.type === "server" ? "server_error" : "error_title"], errorHtml)
            })
    })
    document.querySelectorAll("#lang option").forEach(el => {
        if(el.value === LOCALE) el.setAttribute("selected", "selected");
    });
    document.getElementById("lang").addEventListener("change", () => {
        window.location.replace(`${document.location.origin}?locale=${document.getElementById("lang").value}`);
    });
    document.getElementById("b_navbar").addEventListener("click", toggleMenu);
    document.querySelectorAll("header nav ul li a").forEach(el => el.addEventListener("click", toggleMenu));

    Promise.all([requestText("header-section", LOCALE), requestText("home-page", LOCALE), requestText("work-page", LOCALE), requestText("gallery-page", LOCALE), requestText("contact-page", LOCALE), requestText("contact-feedback", LOCALE)])
        .then(results => {
            fillHeader(results[0]);
            fillHomePage(results[1]);
            fillWorkPage(results[2]);
            fillGalleryPage(results[3]);
            fillContactPage(results[4]);
            contactFeedback = results[5]
        })
        .catch(error => console.log(error))

    requestPaintings(LOCALE)
        .then(paintings => {
            if(paintings.length === 0) return;
            emptyGallery()
            paintings.forEach(painting => {
                let image = createImg(getImageUrl(painting.Image.data.attributes.formats.medium.url), painting.Image.data.attributes.alternativeText)
                document.querySelector("#gallery .galerie").appendChild(image);
                attachPopup(image, () => showDetails(painting.Name, painting.Description, image))
            })
        })
        .catch(error => console.log(error))
}

main();